<template>
  <div id='article'>
    <el-card>
      <el-row :gutter="20" class="operate">
        <el-col :span="4">
          <el-select v-model="selectColumn" placeholder="请选择栏目" filterable clearable>
            <el-option v-for="(item, index) in columnList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select v-model="selectTag" placeholder="请选择标签" filterable clearable>
            <el-option v-for="(item, index) in tagList" :key="index" :label="item.name" :value="item.alias"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-input v-model="selectTitle" placeholder="请输入文章标题" clearable></el-input>
        </el-col>
        <el-button @click="search" icon="el-icon-search" type="primary"></el-button>
        <el-button @click="openDialog(form, true)" type="primary">添加文章</el-button>
      </el-row>
      <el-table v-loading="loading" :data="tableData" style="width: 100%" stripe border>
        <el-table-column fixed="left" prop="id" label="ID" width="100"></el-table-column>
        <el-table-column prop="title" label="标题" width="300"></el-table-column>
        <el-table-column prop="column_name" label="栏目" width="200"></el-table-column>
        <el-table-column prop="tag" label="标签" width="150"></el-table-column>
        <el-table-column prop="desc" label="描述"></el-table-column>
        <el-table-column label="状态" width="200">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" @change="changeStatus(scope.row)" :active-value="1" :inactive-value="0"></el-switch><span class="status_text">{{scope.row.status | statusText}}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="240">
          <template #default="scope">
            <el-button @click="queryItem(scope.row.id)" type="primary" size="mini">查看</el-button>
            <el-button @click="openDialog(scope.row, false)" type="warning" size="mini">修改</el-button>
            <el-button @click="deleteItem(scope.row.id)" type="danger" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.page" :page-sizes="[5, 10, 20, 50, 100, 200]" :page-size="params.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>

      <!-- 添加弹窗 -->
      <el-dialog @close="closeDialog('addArticleItem')" title="添加文章" :visible.sync="showDialog" class="add_dialog">
        <el-form :model="form" ref="addArticleItem" :rules="rules">
          <el-form-item label="标题" prop="title" :label-width="formLabelWidth">
            <el-input v-model="form.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="栏目" prop="column_id" :label-width="formLabelWidth">
            <el-select v-model="form.column_id" placeholder="请选择栏目" filterable clearable>
              <el-option v-for="(item, index) in columnList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标签" prop="tag_class" :label-width="formLabelWidth">
            <el-radio-group v-model="form.tag_class" @change="handleTagChange(form.tag_class)">
              <el-radio :label="item.alias" v-for="(item, index) in tagList" :key="index">{{item.name}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="图片" :label-width="formLabelWidth">
            <el-upload
                    list-type="picture-card"
                    :multiple="true"
                    :action=uploadUrl
                    :file-list="picList"
                    :on-success="handleUploadSuccess"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </el-form-item>
          <el-form-item label="关键词" prop="keywords" :label-width="formLabelWidth">
            <el-autocomplete
                    v-model="inputKeywords"
                    :fetch-suggestions="querySearch"
                    placeholder="请输入关键词"
                    @select="handleSelect"
            ></el-autocomplete>
            <template v-if="form.keywords.length > 0">
              <el-tag v-for="keyword in form.keywords"
                      :key="keyword.name"
                      :closable="true"
                      @close="closeKeywords(keyword)">
                {{keyword.name}}
              </el-tag>
            </template>
          </el-form-item>
          <el-form-item label="描述" prop="desc" :label-width="formLabelWidth">
            <el-input type="textarea" v-model="form.desc"></el-input>
          </el-form-item>
          <el-form-item label="格式" prop="content_type" :label-width="formLabelWidth">
            <el-radio-group v-model="form.content_type">
              <el-radio label="1" @change="changeContentType(form.content_type)">富文本</el-radio>
              <el-radio label="2" @change="changeContentType(form.content_type)">Markdown</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="内容" prop="content" :label-width="formLabelWidth">
            <Editor v-if="form.content_type == 1" v-model="form.content" :is-clear="false"></Editor>
            <mavon-editor v-if="form.content_type == 2" v-model="form.content" @imgAdd="upLoadImage" :subfield="false" ref="markdown"></mavon-editor>
          </el-form-item>
          <el-form-item label="状态" prop="status" :label-width="formLabelWidth">
            <el-switch v-model="form.status" :active-value="1" :inactive-value="0"></el-switch><span class="status_text">{{form.status | statusText}}</span>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button v-if="isAddFlag" @click="addArticle('addArticleItem')" type="primary">确 定</el-button>
          <el-button v-else @click="editItem('addArticleItem')" type="primary">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 查看弹窗 -->
      <el-dialog title="详情" :visible.sync="checkItem" center class="check_dialog">
        <div class="check_div">
          <div class="check_label">标题：</div>
          <div class="check_data">{{checkData.title}}</div>
        </div>
        <div class="check_div">
          <div class="check_label">栏目：</div>
          <div class="check_data">{{checkData.column_name}}</div>
        </div>
        <div class="check_div">
          <div class="check_label">标签：</div>
          <div class="check_data">{{checkData.tag}}</div>
        </div>
        <div class="check_div">
          <div class="check_label">关键词：</div>
          <div class="check_data">
            <el-tag v-for="keyword in checkData.keywords"
                    :key="keyword.name">
              {{keyword.name}}
            </el-tag>
          </div>
        </div>
        <div class="check_div">
          <div class="check_label">描述：</div>
          <div class="check_data">{{checkData.desc}}</div>
        </div>
        <div class="check_div">
          <div class="check_label">内容：</div>
          <div v-if="checkData.content_type == 1" v-html="checkData.content" class="check_data"></div>
          <div v-if="checkData.content_type == 2" class="check_data">
            <mavon-editor v-model="checkData.content" defaultOpen="preview" :editable="false" :toolbarsFlag="false" :navigation="true" :ishljs="true" :subfield="false"></mavon-editor>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="checkItem = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
    import {getArticleList, addArticleItem, getArticleColumns, getTagAll, queryArticleItem, editArticleItem, deleteArticleItem, upLoadImg} from '@api';
    import Editor from "../../components/Editor";

    export default {
        name: 'Article',
        components: {Editor},
        data() {
            return {
                params: { // 请求分页参数
                    page: 1,
                    page_size: 10
                },
                tableData: [], // 表格数据
                loading: true, // 表格加载等待
                showDialog: false, // 是否显示弹窗
                isAddFlag: false, // 是否点击添加用户
                form: { // 添加表单
                    title: '',
                    column_id: '',
                    pics: [],
                    picture_change: false,
                    keywords: [],
                    keywords_change: false,
                    tag: '',
                    tag_class: '',
                    desc: '',
                    content_type: "1", // 默認內容格式为富文本
                    content: '',
                    status: 0,
                },
                initForm: {
                    title: '',
                    column_id: '',
                    pics: [],
                    picture_change: false,
                    keywords: [],
                    keywords_change: false,
                    tag: '',
                    tag_class: '',
                    desc: '',
                    content_type: "1", // 默認內容格式为富文本
                    content: '',
                    status: 0,
                },
                rules: {
                    title: [
                        {required: true, message: '请输入标题', trigger: 'blur'}
                    ],
                    column_id: [
                        {required: true, message: '请选择栏目', trigger: 'blur'}
                    ],
                    content: [
                        {required: true, message: '请选择内容', trigger: 'blur'}
                    ]
                },
                formLabelWidth: '120px', // from表单label的宽度
                total: 0, // 数据总条数
                checkItem: false, // 查看每条的弹窗
                checkData: {}, // 查询每条时的数据
                selectTitle: '', // 搜索标题
                selectColumn: '', // 搜索栏目
                selectTag: '', // 搜索标签
                srcList: [], // 查看图片
                columnList: [], // 栏目数据
                uploadUrl: `${this.$store.state.config.baseURL}/upload`,
                dialogImageUrl: '',
                dialogVisible: false,
                inputKeywords: '',
                keywordsList: [],
                picList: [],
                tagList: [],
            }
        },
        created() {
            this.getData(this.params);
            getArticleColumns().then(res => this.columnList = res.data);
            getTagAll().then(res => this.tagList = res.data);
        },
        filters: {
            statusText(status) {
                if (status > 0) {
                    return '已审核';
                }
                return '未审核';
            }
        },
        methods:{
            handleUploadSuccess(resp, file, fileList) {
                this.form.pics.push({url: resp.data.url});
                this.form.picture_change = true;
            },
            handleRemove(file, fileList) {
                let picIndex = -1;
                this.form.pics.forEach((pic, index) => {
                    if (pic.url === file.url) {
                        picIndex = index;
                    }
                });
                if (picIndex > -1) {
                    this.form.pics.splice(picIndex, 1);
                    this.form.picture_change = true;
                }
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl  = file.url;
                this.dialogVisible = true;
            },
            handleTagChange(tagClass) {
                this.tagList.forEach((tag) => {
                    if (tag.alias === tagClass) {
                        this.form.tag = tag.name;
                    }
                });
            },
            querySearch(queryString, cb) {
                let keywords = this.keywordsList;
                let results = queryString ? keywords.filter(this.createStateFilter(queryString)) : keywords;
                if (queryString && results.length === 0) {
                    results = [{name: queryString, value: queryString}];
                }
                cb(results);
            },
            createStateFilter(queryString) {
                return (keyword) => {
                    return (keyword.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
                };
            },
            handleSelect(item) {
                let results = this.form.keywords.filter((keyword) => {
                    return keyword.name.toLowerCase() === item.value.toLowerCase();
                });
                if (results.length === 0) {
                    this.form.keywords.push({name: item.value});
                }
                results = this.keywordsList.filter((keyword) => {
                    return keyword.value.toLowerCase() === item.value.toLowerCase();
                });
                if (results.length === 0) {
                    this.keywordsList.push(item);
                }
                this.form.keywords_change = true;
                this.inputKeywords = "";
            },
            closeKeywords(item) {
                let itemIndex = -1;
                this.form.keywords.forEach((keyword, index) => {
                    if (keyword.name === item.name) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    this.form.keywords.splice(itemIndex, 1);
                }
                let keywordIndex = 0;
                this.keywordsList.forEach((keyword, index) => {
                    if (keyword.name === item.name) {
                        keywordIndex = index;
                    }
                });
                if (keywordIndex > -1) {
                    this.keywordsList.splice(itemIndex, 1);
                }
                this.form.keywords_change = true;
            },
            search() { // 搜索
                const {selectTitle, selectColumn, selectTag} = this;
                this.params.title = selectTitle;
                this.params.column_id = selectColumn;
                this.params.tag_class = selectTag;
                this.getData(this.params);
            },
            addArticle(refName) { // 添加
                this.$refs[refName].validate(valid => {
                    if (!valid) return false;
                    this.form.status = parseInt(this.form.status);
                    this.form.content_type = parseInt(this.form.content_type);
                    addArticleItem(this.form).then(() => {
                        this.showDialog = false;
                        this.getData(this.params);
                        this.$message.success('添加成功!');
                        this.initFormData();
                    });
                });
            },
            queryItem(id) { // 查询单条数据
                this.checkItem = true;
                this.checkData = {};
                queryArticleItem(id).then(res => this.checkData = res.data);
            },
            editItem(refName) { // 修改单条数据
                this.$refs[refName].validate(valid => {
                    if (!valid) return false;
                    const {form, form: {id}} = this;
                    const body = form;
                    body.status = parseInt(body.status);
                    body.content_type = parseInt(body.content_type);
                    body.fields = Object.keys(this.initForm);
                    editArticleItem(id, body).then(() => {
                        this.showDialog = false;
                        this.$message.success('修改成功！');
                        this.getData(this.params);
                    });
                });
            },
            deleteItem(id) { // 删除单挑数据
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteArticleItem(id).then(() => {
                        this.$message.success('删除成功!');
                        this.getData(this.params);
                    });
                }).catch(() => this.$message.info('已取消删除'));
            },
            changeContentType(type) {
                this.content_type = type;
            },
            changeStatus(item) {
                item.fields = ["status"];
                item.status = item.status ? 1 : 0;
                item.content_type = parseInt(item.content_type);
                editArticleItem(item.id, item).then(() => {
                    this.$message.success('修改成功！');
                    this.getData(this.params);
                });
            },
            openDialog(item, isAdd) { // 打开弹窗
                if (item.hasOwnProperty("id") && item.id > 0) {
                    let _this = this;
                    queryArticleItem(item.id).then(res => {
                        _this.form = {...res.data};
                        _this.form.content_type = item.content_type+"";
                        _this.form.keywords.forEach((keywords) => {
                            _this.keywordsList.push({name: keywords.name, value: keywords.name});
                        });
                        _this.form.pics.forEach((pic) => {
                            _this.picList.push({url: pic.url});
                        });
                    });
                } else {
                    this.form = item;
                    this.form.content_type = item.content_type+"";
                    this.form.keywords.forEach((keywords) => {
                        this.keywordsList.push({name: keywords.name, value: keywords.name});
                    });
                    this.form.pics.forEach((pic) => {
                        this.picList.push({url: pic.url});
                    });
                }
                this.showDialog = true;
                this.isAddFlag = isAdd;
            },
            closeDialog(refName) { // 关闭弹窗
                this.showDialog = false;
                this.keywordsList = [];
                this.picList = [];
                this.initFormData();
                this.$refs[refName].resetFields();
            },
            cancel() {
                this.showDialog = false;
            },
            getSrcList(src) { // 查看图片
                this.srcList = [];
                this.srcList.push(src);
            },
            upLoadImage(pos, file) {
                let formData = new FormData();
                formData.append('file', file);
                upLoadImg(formData).then((res) => {
                    this.$refs.markdown.$img2Url(pos, res.data.url);
                })
            },
            handleCurrentChange(val) { // 获取页数
                this.params.page = val;
                this.getData(this.params);
            },
            handleSizeChange(val) { // 获取每页条数
                this.params.page_size = val;
                this.getData(this.params);
            },
            getData(params) {
                this.loading = true;
                params.fields = 'id,title,column_id,tag,tag_class,column_name,desc,status,content_type';
                getArticleList(params).then(res => {
                    this.loading = false;
                    const {data, count} = res.data;
                    this.total = count;
                    this.tableData = data;
                }).catch(() => {
                    this.loading = false;
                })
            },
            initFormData() { // 初始化数据
                this.form = this.initForm;
            }
        },
    }
</script>

<style lang='less' scoped>
  /deep/ .markdown-body {
    min-height: 500px;
    max-height: 600px;
  }
  /deep/ .el-tag {
    margin-left: 10px;
  }
  /deep/ .check_data .el-tag:first-child {
    margin-left: 0;
  }
</style>